<template>
  <div class="lesson-player">
    <div class="d-none justify-content-between mt-2">
      <h2 class="lesson-title">{{ lessonStore.currentLesson?.title }}</h2>
      <h2 class="lesson-title">{{ lessonStore.currentLessonNext?.title }}</h2>
    </div>
    <div class="video-wrapper mb-3">
      <div v-if="noVideo">Lección sin video</div>

      <iframe
        v-else
        :src="videoSrc"
        frameborder="0"
        allow="encrypted-media"
        allowfullscreen
        ref="videoIframe"
      ></iframe>
    </div>

    <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
  </div>
  <div class="d-lg-flex text-start align-items-start justify-content-between">
    <div>
      <p class="current-course mb-3">
        {{ lessonStore?.currentCourse?.title }}
      </p>

      <div class="d-flex align-items-center mb-3">
        <p class="me-4 mb-0">Evalúa esta clase:</p>
        <StartIcon v-for="i in 5" :key="i" class="me-2" />
      </div>
    </div>
    <button
    v-if="!lessonStore.isConcluded"
      class="btn-conclude"
      :class="{ 'btn-conclude-active': lessonStore.isConcluded }"
      @click="lessonStore.concludeLesson(lessonStore.currentLesson.id)"
    >
      <p class="mb-0">Marcar como concluida</p>
    </button>
  </div>
</template>

<script setup>
import axios from "axios";
import { storeToRefs } from "pinia";
import { defineProps,  ref, watch } from "vue";
import LessonStore from "../stores/lesson.js";
import StartIcon from "./icons/startIcon.vue";

const props = defineProps({
  productId: {
    required: true,
  },
});

const { _currentLesson } = storeToRefs(LessonStore());

const lessonStore = LessonStore();

watch(_currentLesson, async () => {
  if (_currentLesson.value.video_id) {
    console.log("watch 1");
    await getVdoCipherCredentials(_currentLesson.value.video_id);
  } else {
    noVideo.value = true;
  }
});
const noVideo = ref(false);
const videoSrc = ref("");
const errorMessage = ref("");
const videoIframe = ref(null);

async function getVdoCipherCredentials(videoId) {
  try {
    noVideo.value = false;
    const apiUrl = process.env.VUE_APP_API_URL;
    const payload = {
      video_id: videoId,
      product_id: props.productId,
    };

    const response = await axios.post(
      `${apiUrl}/get_vdocipher_credentials/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    const { otp, playbackInfo } = await response.data;
    videoSrc.value = `https://player.vdocipher.com/v2/?otp=${otp}&playbackInfo=${playbackInfo}`;
  } catch (error) {
    console.log(error);
    if (error.response.data.error) {
      // window.location.replace(`https://skilpering.netlify.app/products/buy/${props.productId}`);
    }
  }
}


</script>

<style scoped>
.lesson-player {
  text-align: center;
  /* margin: 12px 0; */
  margin-bottom: 6px;
}
.lesson-title {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: start;
}
.video-wrapper {
  margin: 0 auto;
  /* max-width: 640px; */
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width: 100%;
}
.video-wrapper iframe {
  position: absolute;

  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
@media (min-width: 1500px) {
  .video-wrapper {
    padding-bottom: 48.25%;
  }
}

/* .video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */
.lesson-description {
  margin-top: 10px;
  font-size: 16px;
}
.text-danger {
  color: #dc3545;
}
.current-course {
  text-align: start;
  font-size: 20px;
  font-weight: 500;
}
.btn-conclude {
  border: 1px solid green;
  background: transparent;
  color: green;
  border-radius: 6px;
  padding: 11px;
}
.btn-conclude p::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid green;
  display: inline-block;
  margin-right: 10px;
  color: green;
  margin-bottom: -3px;
}
.btn-conclude-active p::before {
  background-color: green;
}
</style>
import axios from "axios";
import { defineStore } from "pinia";

export default defineStore({
  id: "lesson",
  state: () => ({
    _status: null,
    _error: null,
    _currentCourse: null,
    _currentLesson: null,
    _currentModuleIndex: 0,
    _currentLessonIndex: 0,
    _currentLessonNext: null,
    _isConcluded: false,
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    currentCourse: (state) => state._currentCourse,
    currentLesson: (state) => state._currentLesson,
    currentLessonNext: (state) => state._currentLessonNext,
    isConcluded: (state) => state._isConcluded,
  },
  actions: {
    async getLesson(_id) {
      try {
        this.changeStatus("loading");
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/products/${_id}/product-permission/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        this._currentModuleIndex = 0;
        this._currentLessonIndex = 0;
        this._currentCourse = response.data;
        if (response.data.modules[0] && response.data.modules[0].lessons[0]) {
          this._currentLesson = response.data.modules[0].lessons[0];
          this._currentLessonNext = response.data.modules[0].lessons[1] || null;
        }

        this.changeStatus("ready");
      } catch (error) {
        if (error.response.status === 404) {
          this.changeStatus("error", "Producto no encontrado");
        }

        if (error.response.data.error) {
          window.location.replace(
            `${process.env.VUE_APP_MARKET_URL}/products/buy/${_id}`
          );
        }
        console.log(error.response.data);
        console.error("Error fetching product:", error);
      }
    },
    async concludeLesson(_id) {
      console.log(_id);
      try {
        this.changeStatus("loading");
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/user-lesson/`,
          { lesson_id: _id },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) this._isConcluded = true;
        this.changeStatus("ready");
      } catch (error) {
        console.log(error.response.data);
        console.error("Error fetching product:", error);
      }
    },
    async getConcludedLesson(_id) {
      console.log(_id);
      try {
        this.changeStatus("loading");
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/user-lesson/${_id}/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          this._isConcluded = response.data.completed;
        }

        this.changeStatus("ready");
      } catch (error) {
        console.log(error.response.data);
        console.error("Error fetching product:", error);
      }
    },
    nextLesson() {
      const lessons =
        this._currentCourse.modules[this._currentModuleIndex].lessons;
      if (this._currentLessonIndex < lessons.length - 1) {
        this._currentLessonIndex++;
        this._currentLesson = lessons[this._currentLessonIndex];
        this._currentLessonNext = lessons[this._currentLessonIndex + 1] || null;
      } else if (
        this._currentModuleIndex <
        this._currentCourse.modules.length - 1
      ) {
        this._currentModuleIndex++;
        this._currentLessonIndex = 0;
        this._currentLesson =
          this._currentCourse.modules[this._currentModuleIndex].lessons[0];
        this._currentLessonNext =
          this._currentCourse.modules[this._currentModuleIndex].lessons[1] ||
          null;
      }
      this.getConcludedLesson(this._currentLesson.id);
    },
    prevLesson() {
      if (this._currentLessonIndex > 0) {
        this._currentLessonIndex--;
        this._currentLesson =
          this._currentCourse.modules[this._currentModuleIndex].lessons[
            this._currentLessonIndex
          ];
        this._currentLessonNext =
          this._currentCourse.modules[this._currentModuleIndex].lessons[
            this._currentLessonIndex + 1
          ] || null;
      } else if (this._currentModuleIndex > 0) {
        this._currentModuleIndex--;
        this._currentLessonIndex =
          this._currentCourse.modules[this._currentModuleIndex].lessons.length -
          1;
        this._currentLesson =
          this._currentCourse.modules[this._currentModuleIndex].lessons[
            this._currentLessonIndex
          ];
          this.getConcludedLesson(this._currentLesson.id);

      }
    },
    setCurrentLesson(lesson) {
      this._currentLesson = lesson;
    },
    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
  },
});
<template>
  <div class="container-fluid" v-if="lessonStore.isError">
    <pre>{{ lessonStore.error }}</pre>
  </div>
  <div class="dash" v-else>
    <div class="row">
      <div class="col-md-12 col-lg-3 col-xl-3">
        <LessonSidebar
          @lessonSelected="handleLessonSelected"
          :title="lessonStore.currentCourse?.title"
          :modules="lessonStore.currentCourse?.modules"
        />
      </div>
      <div class="col-md-12 col-lg-9 col-xl-9">
        <div class="content">
          <div class="lesson-navigation d-flex justify-content-between">
            <button
              @click="lessonStore.prevLesson()"
              class="btn-lesson align-items-start"
            >
              <div><i class="fas fa-arrow-left"></i> Anterior</div>
              <h6 class="lesson-title text-white text-start mt-2">
                {{ lessonStore.currentLesson?.title }}
              </h6>
            </button>

            <button
              @click="lessonStore.nextLesson()"
              class="btn-lesson align-items-end"
            >
              <div>Siguiente <i class="fas fa-arrow-right"></i></div>
              <h6 class="lesson-title text-white text-end mt-2">
                {{ lessonStore.currentLessonNext?.title }}
              </h6>
            </button>
          </div>
          <LessonPlayer :productId="productId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import LessonPlayer from "@/components/LessonPlayer.vue"
import LessonSidebar from "@/components/SidebarLesson.vue"
import LessonStore from "@/stores/lesson.js"
// import { useAuth0 } from "@auth0/auth0-vue"
import { onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { useAuth0 } from '@auth0/auth0-vue'

const { getAccessTokenSilently, isAuthenticated } = useAuth0();

const router = useRouter();
const lessonStore = LessonStore();

const productId = ref(null);

function handleLessonSelected(lesson) {
  lessonStore.setCurrentLesson(lesson);
}

onMounted(async () => {
  if (isAuthenticated.value) {
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.VUE_APP_AUTH0_AUDIENCE}`,
      });
      sessionStorage.setItem('token', token);

      productId.value = router.currentRoute.value.params.id;
      await lessonStore.getLesson(router.currentRoute.value.params.id);
    } catch (error) {
      console.error("Error al obtener el token:", error);
      router.push('/');
    }
  } else {
    router.push('/');
  }
});
</script>

<style scoped>
.container-fluid {
  display: flex;
}
.content {
  padding: 20px;
}
.lesson-navigation {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}
.lesson-evaluation {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.lesson-evaluation p {
  margin-right: 10px;
}
.lesson-evaluation .fa-star {
  color: #ffc107;
}
.btn-lesson {
  background: transparent;
  color: #22c55e;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
.dash {
  overflow: hidden;
  /* height: 93.5vh; */
}
.sidebar {
  /* border-bottom: 1px solid #4d4d4d; */
}
@media (max-width: 768px) {
  .dash {
    overflow: auto;
    height: auto;
  }
  .sidebar {
    height: auto;
    border-bottom: none;
  }
  .content {
    display: flex;
    flex-direction: column-reverse;
    height: 80vh;
    justify-content: space-between;
    padding-top: 0;
  }
}
</style>